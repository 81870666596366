import { useEffect, useState } from "react"

import Chart from 'chart.js/auto' // dont use treeshaker?
import { Doughnut, Line, Pie, Bar } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import cloneDeep from 'lodash/cloneDeep'
import MapChart from "./MapChart"
import { merge } from "lodash"
import dateFormat from "dateformat"
import JSTable from "./JsTable"
import { useContext } from "react"
import { APIParamsContext } from "../App"
import 'bootstrap-icons/font/bootstrap-icons.css';

const humanizeDuration = require("humanize-duration")
const countries = require("i18n-iso-countries")

Chart.register(zoomPlugin)
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))

/**
 * Used for visualizing various Jet-Stream datasets using Chart.js
 *
 * @param {*} props
 * @returns
 */
function JsChart(props) {
  const { api_params, setApiParams } = useContext(APIParamsContext)

  const chart_colors_js = [
    'rgb(216,72,72)',
    'rgb(243, 139, 1)',
    'rgb(255,205, 86)',
    'rgb(75, 192, 192)',
    'rgb(99, 143, 192)'
  ]

  const [chart_data, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: chart_colors_js,
      }
    ]
  })

  const [table_data, setTableData] = useState([])
  const [api_data, setApiData] = useState([])

  const [data_type, setDataType] = useState(null)

  //Used for including the fancy donut/pie charts. Determines how many items should be included
  const max_chart_elements = 5
  const [chartjs_options, setChartJsOptions] = useState({})


  useEffect(() => {
    if (api_params && api_params.begin && api_params.end) {
      // here we want to call the api function with the normal parameters but we want to use the prop asset type in case it's available
      //  this is because the vod and live tabels should always show only the assets of that type
      props.api_data_function({
        ...api_params,
        "type": props.asset_type ?? api_params.type ?? null
      })
        .then((data) => {
          setDataType(data.type)
          setApiData(data)

          //This app will be included in an Iframe. This code makes sure the width and heights of that iframe are correct by sending a message

          //This whole setTimeout code seems not ideal. I wasn't able to find a proper DOM size change api so far sadly
          setTimeout(() => {
            let message = { height: document.body.scrollHeight, width: document.body.scrollWidth }

            // window.top refers to parent window
            window.top.postMessage(message, "*")
          }, 100)

        })
    }
  }, [api_params.end,api_params.begin,api_params.country,api_params.ID])

  useEffect(() => {
    if (props.chartjs_options) {
      setChartJsOptions(props.chartjs_options)
    }
  }, [props.chartjs_options])

  //Used for updating the data upon new data from the API.
  // Can propbably just be included in the .then() change
  useEffect(() => {
    if (api_data.data) {
      return
    }
    setDataType(null)

    // Reset the chart
    const new_chart_data = cloneDeep(chart_data)
    new_chart_data.labels = []
    new_chart_data.datasets[0].data = []
    setChartData(new_chart_data)

    // Reset the table data
    setTableData([])

  }, [api_data])

  //also used when api_data updates ¯\_(ツ)_/¯
  // TODO: hook this into the .then chain of the api
  useEffect(() => {
    let table_data = null

    const new_chart_data = cloneDeep(chart_data)
    if (data_type === 'referrers') {
      const chart_data = []
      const chart_labels = []
      table_data = {
        colum_names: ["Referrer", "Amount"],
        items: []
      }

      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.amount)
          chart_labels.push(record.uri)
        }
        const referrer_object = {
          values: [{
            readableValue: record.uri,
            sortableValue: record.uri
          },
            {
              readableValue: record.amount,
              sortableValue: record.amount
            }]
        }
        table_data.items.push(referrer_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels
      setChartData(new_chart_data)
    }
    if (data_type === 'asset list') {
      const chart_data = []
      const chart_labels = []
      table_data = {
        colum_names: ["Name", "Views", "Avg session duration"],
        items: []
      }
      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.totalViewers)
          chart_labels.push(record.asset.assetName)
        }
        const asset_object = {
          values: [{
            readableValue: <>
              <span className={"table-legend-indicator"}
                style={(i < max_chart_elements ? { backgroundColor: chart_colors_js[i] } : {})}>
              </span>&nbsp; {record.asset.assetName} {record.isRadioStream ? <i class="bi bi-speaker"></i> : ""}
            </>,
            sortableValue: record.asset.assetName
          },
            {
              readableValue: record.totalViewers,
              sortableValue: record.totalViewers
            },
            {
              readableValue: humanizeDuration(record.averageWatchTime * 1000, { largest: 2 }),
              sortableValue: record.averageWatchTime
            }]
        }
        table_data.items.push(asset_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (data_type === 'operating_systems') {
      const chart_data = []
      const chart_labels = []

      table_data = {
        colum_names: ["Operating system", "Amount"],
        items: []
      }
      // Build new datasets
      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.amount)
          chart_labels.push(record.operatingSystem)
        }
        const os_object = {
          values: [{
            readableValue: <>
              <span className={"table-legend-indicator"}
                style={(i < max_chart_elements ? { backgroundColor: chart_colors_js[i] } : {})}>
              </span>&nbsp; {record.operatingSystem}
            </>,
            sortableValue: record.operatingSystem
          },
          {
            readableValue: record.amount,
            sortableValue: record.amount
          }],
        }
        table_data.items.push(os_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (data_type === 'browsers') {
      const chart_data = []
      const chart_labels = []

      table_data = {
        colum_names: ["Browser", "Amount"],
        items: []
      }
      // Build new datasets
      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.amount)
          chart_labels.push(record.operatingSystem)
        }
        const os_object = {
          values: [{
            readableValue: <>
              <span className={"table-legend-indicator"}
                style={(i < max_chart_elements ? { backgroundColor: chart_colors_js[i] } : {})}>
              </span>&nbsp; {record.browser}
            </>,
            sortableValue: record.browser
          },
          {
            readableValue: record.amount,
            sortableValue: record.amount
          }],
        }

        table_data.items.push(os_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (data_type === 'devices') {
      const chart_data = []
      const chart_labels = []

      table_data = {
        colum_names: ["Device", "Amount"],
        items: []
      }
      // Build new datasets
      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.amount)
          chart_labels.push(record.device_type)
        }
        const os_object = {
          values: [{
            readableValue: <>
              <span className={"table-legend-indicator"}
                style={(i < max_chart_elements ? { backgroundColor: chart_colors_js[i] } : {})}>
              </span>&nbsp; {record.device_type}
            </>,
            sortableValue: record.device_type
          },
          {
            readableValue: record.amount,
            sortableValue: record.amount
          }],
        }

        table_data.items.push(os_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (data_type === 'cities') {
      const chart_data = []
      const chart_labels = []
      table_data = {
        colum_names: ["City","Country", "Amount"],
        sorted_column: 2,
        items: []
      }

      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.views)
          chart_labels.push(record.city.city)
        }
        const city_object = {
          values: [{
            readableValue: <>
              <span className={"table-legend-indicator"}
                style={(i < max_chart_elements ? { backgroundColor: chart_colors_js[i] } : {})}>
              </span>&nbsp; {record.city.city}
            </>,
            sortableValue: record.city.city,
          },
          {
            readableValue: record.city.country,
            sortableValue: record.city.country
          },
          {
            readableValue: record.views,
            sortableValue: record.views
          }]
        }
        table_data.items.push(city_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (data_type === 'regions') {
      const chart_data = []
      const chart_labels = []
      table_data = {
        colum_names: ["Region","Country", "Amount"],
        sorted_column: 2,
        items: []
      }

      api_data.data.forEach((record, i) => {
        if (i < max_chart_elements) {
          chart_data.push(record.views)
          chart_labels.push(record.region.region)
        }
        const region_object = {
          values: [{
            readableValue: <>
              <span className={"table-legend-indicator"}
                style={(i < max_chart_elements ? { backgroundColor: chart_colors_js[i] } : {})}>
              </span>&nbsp; {record.region.region}
            </>,
            sortableValue: record.region.region,
            // filterableValue: record.country.countryCode
          },
          {
            readableValue: record.region.country,
            sortableValue: record.region.country
          },
          {
            readableValue: record.views,
            sortableValue: record.views
          }]
        }
        table_data.items.push(region_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (data_type === 'countries') {
      const chart_data = []
      const chart_labels = []
      table_data = {
        colum_names: ["Country", "Amount"],
        items: []
      }

      // Build new datasets
      api_data.data.forEach((record, i) => {
        chart_data.push(
          [record.views]
        )
        chart_labels.push(record.country.countryCode)
        const country_object = {
          values: [{
            readableValue: countries.getName(record.country.countryCode, "en", { select: "alias" }),
            sortableValue: countries.getName(record.country.countryCode, "en", { select: "alias" }),
            filterableValue: record.country.countryCode
          },
            {
              readableValue: record.views,
              sortableValue: record.views
            }],
        }
        table_data.items.push(country_object)
      })
      new_chart_data.datasets[0].data = chart_data
      new_chart_data.labels = chart_labels

      // Apply new states
      setChartData(new_chart_data)
    }
    if (['viewers-over-time', 'traffic-over-time'].includes(data_type)) {
      //In previous code we needed the cloneDeep to properly update the chart.
      //  This however introduced weird glitches where the graphs wouldn't always properly update.
      const new_chart_data = props.data
      new_chart_data.labels = []
      new_chart_data.datasets[0].data = []
      api_data.data.forEach(record => {
        const date = new Date(record.datetime)
        if (api_params.bucket === "hour")
          new_chart_data.labels.push(dateFormat(date, "HH:MM dd-mm-yyyy"))
        else
          new_chart_data.labels.push(dateFormat(date, "dd-mm-yyyy"))
        new_chart_data.datasets[0].data.push(record.number)
      })

      setChartData(new_chart_data)
    }
    if (table_data) {

      setTableData(table_data)
    }
  }, [api_data])



  const [chart,setChart] = useState(null)

  useEffect(() => {
    let chart = null
    let options = {
      plugins: {
        legend: {
          display: false,
        }
      },
      clip: 0,
      borderWidth: 0,
      weight: 5
    }

    // Merge the options in from the props
    options = merge(chartjs_options, options)

    if (props.aspectRatio) {
      options.aspectRatio = props.aspectRatio
    }
    else {
      options.aspectRatio = 2
    }
    switch (props.type) {
      case 'line':
        chart = <Line data={chart_data} options={options} />
        break
      case 'bar':
        chart = <Bar data={chart_data} options={options} />
        break
      case 'pie':
        chart = <Pie data={chart_data} options={options} />
        break
      case 'map':
        chart = <MapChart data={chart_data} />
        break
      default:
        chart = <Doughnut data={chart_data} options={options} className={props.className} />
    }
    setChart(chart)
  }, [chart_data])

  return (
    <>
      {chart_data &&
        <>
          {chart}
        </>
      }
      {
      (() => {
        if(table_data.items) {
          return <JSTable enablePagination={true} data={table_data} filter_param={props.filter_param} style={{ marginTop: "30px", padding: "50px" }}></JSTable>
        }
      })()
    }
    </>
  )
}

export default JsChart
