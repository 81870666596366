import Filters from "./components/Filters"
import "@fontsource/roboto"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useEffect, useState, createContext, useMemo, useContext } from "react"
import JsChart from "./components/JsChart"
import { Button, Card } from "react-bootstrap"
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import JSAPI from './API/JSAPI'

import 'bootstrap-daterangepicker/daterangepicker.css'
import prettyBytes from 'pretty-bytes'
import cloneDeep from "lodash/cloneDeep"
import CountUp from 'react-countup'

import './App.scss'


function getBytesNoPostfix(total_traffic) {
  const splitted = prettyBytes(total_traffic).split(' ')
  return [splitted[0], splitted.pop()]
}

export const APIParamsContext = createContext({
  asset: {
    ID: null,
    type: null
  },
  setAsset: () => { },
})

function SessionsCountUp(props) {
  const [total_sessions, setTotalSessions] = useState(0)
  const { api_params, setApiParams } = useContext(APIParamsContext)
  useEffect(() => {
    props.api_data_function(api_params).then((viewerObject) => {
      setTotalSessions(viewerObject.data)
    })
  }, [api_params])

  return <CountUp duration={1} useEasing={true} preserveValue={true} end={total_sessions} />
}

function customTrafficFormatter(number,unit) {
  return getBytesNoPostfix(number)[0] + " " + getBytesNoPostfix(number)[1]
}

function TrafficCountUp(props) {
  const [total_traffic, setTotalTraffic] = useState(0)
  const { api_params, setApiParams } = useContext(APIParamsContext)
  useEffect(() => {
    props.api_data_function(api_params).then((trafficObject) => {
      setTotalTraffic(trafficObject.traffic_total)
    })
  }, [api_params])

  return <CountUp decimals={3} formattingFn={customTrafficFormatter} duration={1} useEasing={true} preserveValue={true} end={total_traffic}  />
}

const api = JSAPI()
export default function App() {
  //Some voodoo magic to make Context changable by children
  //See https://dmitripavlutin.com/react-context-and-usecontext/
  // Paragraph 4
  const [api_params, overrideApiParams] = useState({
    begin: moment().subtract(24, 'hours'),
    end: moment(),
    bucket: "hour"
  })
  const setApiParams = (new_params) => {
    let raw_params = { ...api_params, ...new_params }
    if (raw_params.begin && raw_params.end) {
      const time_difference_in_hours = (raw_params.end - raw_params.begin) / 1000 / 3600
      raw_params = { ...raw_params, bucket: calculateBucketSize(time_difference_in_hours)}
    }
    const params_without_null = {}
    for (const key in raw_params) {
      if (raw_params[key] !== null) {
        params_without_null[key] = raw_params[key]
      }
    }

    overrideApiParams(params_without_null)
  }
  const api_params_value = useMemo(
    () => ({ api_params, setApiParams }),
    [api_params]
  )


  //the resolution of the date (time bucket size) is determined by checking how long of a time_difference is selected
  //  see https://git.jet-stream.nl/js/cockpit-statistics-react/-/issues/5
  function calculateBucketSize(time_difference_in_hours) {
    //small screens don't deal well with many data points so we don't allow hour buckets on those
    let is_medium = window.innerWidth >= 768;
    if (is_medium && time_difference_in_hours < 48)
      return "hour"
    if (time_difference_in_hours < 24 * 90)
      return "day"
    if (time_difference_in_hours < 24 * 365 * 4)
      return "month"
    else
      return "year"
  }


  function setStartEndRange(start, end) {
    setApiParams({
      begin: start,
      end: end,
    })
  }


  const top_charts = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: 'white'
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: 'white'
        }
      }
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x',
          onZoomComplete: (chart) => {
            const x_scale = chart.chart.options.scales.x
            const min = (x_scale.min < 0 ? 0 : x_scale.min)
            const max = x_scale.max
            const labels = chart.chart.data.labels

            let format = ""
            switch (api_params.bucket) {
              case "hour":
                format = "HH:mm DD-MM-YYYY"
                break;
              default:
                format = 'DD-MM-YYYY'
            }

            const min_moment = moment(labels[min], format)
            const max_moment = moment(labels[max], format)

            setApiParams({
              begin: moment(min_moment),
              end: moment(max_moment)
            })
          }
        },
      }
    }
  }
  let top_chart_traffic = cloneDeep(top_charts)
  top_chart_traffic.scales.y.ticks.callback = function (value, index, ticks) {
    return prettyBytes(value)
  }

  useEffect(() => {
    // set default range
    if (api_params.begin === null) {
      setApiParams({
        begin: moment().subtract(24, 'hours'),
        end: moment(),
        bucket: "hour"
      })
    }
  }, [])

  function dateRangeUpdate(event, picker) {
    setApiParams({
      begin: moment(picker.startDate),
      end: moment(picker.endDate)
    })
    setActiveButton(null)
  }



  //used to remember which of the date-range buttons to color orange
  const [active_button, setActiveButton] = useState(0)
  return (
    <>
      <APIParamsContext.Provider value={api_params_value}>
        <Container>
          <Row>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="stats-header">
                    <Col lg={2}>
                      <h4><b>StreamStats</b></h4>
                    </Col>
                    <Col lg={4}>
                      {api_params.begin &&
                        <>{api_params.begin.format('dddd MMMM DD YYYY')} - {api_params.end.format('dddd MMMM DD YYYY')}</>
                      }
                    </Col>
                    <Col lg={6} className={"text-end"}>
                      <Button className={'m-1 sort-button js-button ' + (active_button === 0 ? "js-orange-bg" : "")} size={'sm'} onClick={() => { setStartEndRange(moment().subtract(24, "h"), moment()); setActiveButton(0) }}>24 hours</Button>
                      <Button className={'m-1 sort-button js-button ' + (active_button === 1 ? "js-orange-bg" : "")} size={'sm'} onClick={() => { setStartEndRange(moment().startOf('isoWeek'), moment().endOf('isoWeek')); setActiveButton(1) }}>This Week</Button>
                      <Button className={'m-1 sort-button js-button ' + (active_button === 2 ? "js-orange-bg" : "")} size={'sm'} onClick={() => { setStartEndRange(moment().startOf('month'), moment().endOf('month')); setActiveButton(2) }}>This Month</Button>
                      <Button className={'m-1 sort-button js-button ' + (active_button === 3 ? "js-orange-bg" : "")} size={'sm'} onClick={() => { setStartEndRange(moment().startOf('year'), moment().endOf('year')); setActiveButton(3) }}>This Year</Button>
                      <div class="vr mx-2 align-middle"></div>
                      {api_params.begin &&
                        <DateRangePicker
                          initialSettings={{ startDate: api_params.begin, endDate: api_params.end }}
                          onApply={dateRangeUpdate}
                          >
                          <Button className={'m-1 sort-button js-gray-bg js-button'} size={'sm'}>Custom </Button>
                        </DateRangePicker>
                      }
                    </Col>
                  </Row>
                  <Row >
                    <div>
                      <Filters filters={api_params} setFilters={setApiParams} />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className={'pt-4'}>
            <Col>
              <Card className={'js-blue-bg'}>
                <Card.Body style={{ color: "white" }}>
                  <Row>
                    <Col lg={2}>
                      <h4>
                        Traffic&nbsp;<small className={"d-inline d-lg-none"}></small>
                      </h4>
                      <h1 style={{ fontSize: '3.5rem', position: 'absolute', bottom: 0 }} className={"d-none d-lg-block"}>
                        <TrafficCountUp api_data_function={api.getTotalTraffic}/>
                      </h1>
                    </Col>
                    <Col lg={10}>
                      <JsChart type={'bar'} api_data_function={api.getTrafficOverTime} chartjs_options={top_chart_traffic} data={{
                        datasets: [
                          {
                            data: [],
                            label: 'Traffic',
                            backgroundColor: 'rgb(255, 255, 255)',
                            tooltip: {
                              callbacks: {
                                label: context => prettyBytes(context.parsed.y)
                              }
                            }
                          }
                        ],
                        labels: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
                      }} aspectRatio={7} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className={'pt-4'}>
            <Col>
              <Card className={'js-blue-bg'}>
                <Card.Body style={{ color: "white" }}>
                  <Row>
                    <Col lg={2}>
                      <h4>
                        Sessions&nbsp; <small className={"d-inline d-lg-none"}></small>
                      </h4>
                      <h1 style={{ fontSize: '3.5rem', position: 'absolute', bottom: 0 }} className={"d-none d-lg-block"}>
                        <SessionsCountUp api_data_function={api.getTotalViewers}/>
                      </h1>
                    </Col>
                    <Col lg={10}>
                      <JsChart type={'line'} api_data_function={api.getViewersOverTime} chartjs_options={top_charts} data={{
                        datasets: [
                          {
                            data: [1500, 1337, 500, 2487, 10, 5899, 1457, 1457, 1167, 1500, 1337, 500, 2487, 10, 5899, 1457, 1457, 1167, 1500, 1337, 500, 2487, 10, 5899, 1457, 1457],
                            label: 'Sessions',
                            parsing: {
                              yAxisKey: 'net'
                            },
                            tension: 0.5,
                            borderColor: 'rgb(255, 255, 255)',
                            borderWidth: 1.5,
                            pointBackgroundColor: 'rgb(255, 255, 255)',
                            pointRadius: 4,
                            pointHoverRadius: 6,
                            tooltip: {
                              callbacks: {
                                label: context => context.parsed.y
                              }
                            }
                          }
                        ],
                        labels: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
                      }} aspectRatio={7} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className={'pt-4'}>
            <Col xl="6" className="pb-4 pb-xl-0">
              <Card className="h-100 asset-card">
                <Card.Body>
                  <Card.Subtitle>Top video assets</Card.Subtitle>
                  {/* <JsChart type={'pie'} aspectRatio={2}/> */}
                  {api_params.type !== "live" && <JsChart asset_type="vod" filter_param={{ value: api_params.ID, setValue: value => setApiParams({ ID: value }) }} aspectRatio={2} showTable={true} api_data_function={api.getLivestreamAssets} />}
                </Card.Body>
              </Card>
            </Col>
            <Col xl="6" >
              <Card className="h-100 asset-card">
                <Card.Body>
                  <Card.Subtitle>Top livestream assets</Card.Subtitle>
                  {api_params.type !== "vod" && <JsChart asset_type="live" filter_param={{ value: api_params.ID, setValue: value => setApiParams({ ID: value }) }} api_data_function={api.getLivestreamAssets} aspectRatio={2} showTable={true} />}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className={'pt-4'}>
            <Col className="pb-4 pb-xl-0">
              <Card className="h-100">
                <Card.Body>
                  {/* <Card.Subtitle>Operating systems</Card.Subtitle> */}
                  <Tabs defaultActiveKey="devices" id="user-agent-tabs" className="mb-5" mountOnEnter={true}>
                    <Tab eventKey="devices" title="Devices">
                      <JsChart api_data_function={api.getDevices} aspectRatio={2} showTable={true} />

                    </Tab>
                    <Tab eventKey="os" title="Operating systems">

                      <JsChart api_data_function={api.getOperatingSystems} aspectRatio={2} showTable={true} />
                    </Tab>
                    <Tab eventKey="browsers" title="Browsers">
                      <JsChart api_data_function={api.getBrowsers} aspectRatio={2} showTable={true} />
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="h-100">
                <Card.Body>
                  <Tabs defaultActiveKey="countries" id="geo-tabs" mountOnEnter={true}>
                    <Tab eventKey="countries" title="Countries">
                      <JsChart debug={true} type={'map'} api_data_function={api.getCountries} showTable={true} filter_param={{ value: api_params.country, setValue: value => setApiParams({ country: value }) }} />
                    </Tab>
                    <Tab eventKey="regions" title="Regions">
                      <JsChart debug={true} api_data_function={api.getRegions} className={"mt-5"} showTable={true}  aspectRatio={2}/>
                    </Tab>
                    <Tab eventKey="cities" title="Cities">
                      <JsChart debug={true} api_data_function={api.getCities} className={"mt-5"} showTable={true} aspectRatio={2}/>
                    </Tab>

                  </Tabs>

                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className={'pt-4'}>
            <Col>
              <Card className="h-100">
                <Card.Body>
                  <Card.Subtitle>Referrers</Card.Subtitle>
                  <JsChart aspectRatio={2} api_data_function={api.getReferrers} showTable={true}/>
                </Card.Body>
              </Card>
            </Col>
            <Col>
            </Col>
          </Row>
        </Container>
      </APIParamsContext.Provider>
    </>
  )
}
