
import { Button, Card } from "react-bootstrap"
const countries = require("i18n-iso-countries")
const label_colors = {
  ID: 'text-bg-danger',
  country: 'text-bg-info'
}
const black_list = ['bucket', 'begin', 'end']
const permanent_filters = ['begin', 'end']

const filter_renderers = {
  begin: filter => filter.format(),
  end: filter => filter.format(),
  country: country => countries.getName(country, "en", { select: "alias" })
}

export default function Filters(props) {
  function removeFromApiParams(param) {
    if (permanent_filters.some(filter => param === filter))
      return;
    const params_local = {}
    params_local[param] = null
    props.setFilters(params_local)
  }

  //initial invisible filter to reserve height
  let filter_jsx = [<Button className={"filter-button sort-button js-button"} id="placeholder-filter"><i class="bi bi-x"></i></Button>]
  for (const filter in props.filters) {
    if (black_list.includes(filter)) continue
    filter_jsx.push(
      <Button className={'filter-button sort-button js-button '} size={'sm'} onClick={() => removeFromApiParams(filter)}><i class="bi bi-x close-icon"></i>{filter + ":   " + (filter in filter_renderers ? filter_renderers[filter](props.filters[filter]) : props.filters[filter])} </Button>
    )
  }
  return filter_jsx
}