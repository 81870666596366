import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps"
import { Button } from "react-bootstrap"
import { useEffect, useState, useContext } from "react"
import { APIParamsContext } from "../App"

const countries = require("i18n-iso-countries")

function MapChart(props) {
  const { api_params, setApiParams } = useContext(APIParamsContext)
  const blue_js = '#8BB7E8'
  const gray_js = '#516B7D'

  const [highlight_country, setHighlightCountry] = useState(null)
  const [views, setViews] = useState(null)
  const [map_zoom, setMapZoom] = useState(1)
  const [map_position, setMapPosition] = useState([0, 0])
  const [chart_data, setChartData] = useState(null)

  function setMapZoomBounded(zoom_level) {
    //nice operator logic to make sure that zooming in is always possible when zoomed out too far and vice-versa
    if ((zoom_level >= 1 || map_zoom < zoom_level) && (zoom_level <= 3.5 || map_zoom > zoom_level))
      setMapZoom(zoom_level)

  }

  useEffect(() => {
    if (props.data) {
      const data = props.data
      const new_chart_data = {}
      let max_value = data.datasets[0].data[0]
      data.datasets[0].data.forEach((row, i) => {
        const country = data.labels[i]
        const value = row[0]
        new_chart_data[country] = {
          value: value,
          opacity: value / max_value
        }
      })
      setChartData(new_chart_data)
    }
  }, [props.data])

  function handleMoveEnd(position) {
    setMapPosition(position.coordinates)
    setMapZoom(position.zoom)
  }

  return <>
    <ComposableMap className="mb-3">
      <ZoomableGroup center={map_position} zoom={map_zoom} onMoveEnd={handleMoveEnd}>
        <Geographies geography="/features.json">
          {
            ({ geographies }) => {
              return geographies.map((geo) => {
                //our data is stored using ISO 3166-1 Alpha 3 but the map uses Alpha 2
                const id_alpha_2 = countries.alpha3ToAlpha2(geo.id)
                let record = {
                  value: 0,
                  opacity: 1,
                  rgb_background: [245, 245, 245]
                }
                if (chart_data[id_alpha_2]) {
                  const row = chart_data[id_alpha_2]
                  record = {
                    opacity: row.opacity + .2,
                    value: row.value,
                    rgb_background: [243, 139, 0]
                  }
                }

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      setHighlightCountry(geo.properties.name)
                      setViews(record.value)
                    }}
                    onMouseLeave={() => {

                    }}
                    onClick={
                      (event) => {
                        // prevent the map from zooming in when clicking on a country
                        event.preventDefault()
                        // if the country is already selected, deselect it
                        if(id_alpha_2 === api_params.country) {
                          setApiParams({ country: null })
                        }
                        else {
                          setApiParams({ country: id_alpha_2 })
                        }
                      }
                    }
                    style={
                      id_alpha_2 === api_params.country ? {
                        default: {
                          fill: gray_js,
                          outline: "none"
                        },
                        hover: {
                          fill: gray_js,
                          outline: "none"
                        },
                        pressed: {
                          fill: blue_js,
                          outline: "none"
                        }
                      }
                        :
                        {
                          default: {
                            fill: "rgba(" + record.rgb_background[0] + ", " + record.rgb_background[1] + ", " + record.rgb_background[2] + ", " + record.opacity + ")",
                            outline: "none"
                          },
                          hover: {
                            fill: blue_js,
                            outline: "none"
                          },
                          pressed: {
                            fill: blue_js,
                            outline: "none"
                          }
                        }
                    }

                  />
                )
              }
              )
            }
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
    <Button onClick={() => { setMapZoomBounded(map_zoom - .5) }} style={
      { "margin-right": "0.5rem" }
    } className={map_zoom <= 1 ? "inactive-button" : ''}>-</Button>   <Button onClick={() => { setMapZoomBounded(map_zoom + .5) }} style={
      { "margin-right": "0.5rem" }
    } className={map_zoom >= 3.5 ? "inactive-button" : ''}>+</Button>
    Country: {highlight_country} {views} hits
  </>
}

export default MapChart
