
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';

export default function JsModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  useEffect(() => {
    setShow(props.show)
  }, [props.show])

  useEffect(() => {
    if(!show && typeof props.onClose === "function"){
      console.log("closing")
      props.onClose()
      let message = { pageDim: false }

      // window.top refers to parent window
      window.top.postMessage(message, "*")
    }
    //show is true
    else {
      let message = { pageDim: true }

      // window.top refers to parent window
      window.top.postMessage(message, "*")
    }

  }, [show])

  return <>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>


}