import { useEffect } from "react"
import { useState } from "react"
import { Table } from "react-bootstrap"
import JsModal from "./JsModal"
export default function JSTable(props) {

  const [sort_order_is_ascending, setSortOrderIsAscending] = useState(false)
  const [sorted_column, setSortedColumn] = useState({
    column: props.data.sorted_column ?? 1,
    ascending: false
  })
  const [table_data, setTableData] = useState([])
  const [full_data, setFullData] = useState(props.data)
  const [current_page, setCurrentPage] = useState(0)

  function setPageAndResetSelected(page_number) {
    setCurrentPage(page_number)
  }

  const [page_buttons, setPageButtons] = useState([])

  const page_size = 10

  // function closeModal

  //Please make this number even otherwise I will cry
  const max_page_elements_shown = 6

  useEffect(() => {
    if(!props.data)
      return
    // reset the page to avoid page showing empty result upon filters changing
    setCurrentPage(0)
    setFullData(props.data)
  }, [props.data])

  useEffect(() => {
    const page_buttons_local = []

    if (current_page > max_page_elements_shown / 2) {
      page_buttons_local.push(<li className="page-item"><a className="page-link disabled">...</a></li>)
    }

    for (let i = Math.max(0, current_page - (max_page_elements_shown / 2)); i < Math.min(full_data.items.length / page_size, current_page + (max_page_elements_shown / 2)); i++) {
      page_buttons_local.push(<li className={"page-item" + ((current_page === i) ? " active" : "")}><a className={"page-link"} href="#/" onClick={() => { setPageAndResetSelected(i) }}>{i + 1}</a></li>)
    }

    if (current_page < (full_data.items.length / page_size) - max_page_elements_shown / 2) {
      page_buttons_local.push(<li className="page-item"><a className="page-link disabled">...</a></li>)
    }

    setPageButtons(page_buttons_local)
  }, [full_data, current_page])

  function compareNumbers(first, second) {
    if (first === second)
      return 0
    else if (first > second) {
      return 1
    }
    else {
      return -1
    }
  }
  const [shown_indices, setShownIndices] = useState([])
  // for sorting and pagination
  useEffect(() => {
    //reset shown Indices
    setShownIndices([])
    let data = full_data
    if (data && sorted_column) {
      data = data.items.sort((a, b) => {
        //make sure "other" is always at the bottom of the table
        const anchorFirst = a.values[sorted_column.column].anchor ?? null
        const anchorSecond = b.values[sorted_column.column].anchor ?? null
        if (anchorFirst === "bottom")
          return 1
        if (anchorSecond === "bottom")
          return -1
        let first = a.values[sorted_column.column].sortableValue
        let second = b.values[sorted_column.column].sortableValue

        //Treating upper and lowercase differently doesn't make much sense so they are treated the same
        if (typeof first === "string")
          first = first.toLowerCase()
        if (typeof second === "string")
          second = second.toLowerCase()

        if (sort_order_is_ascending) {
          if (typeof first === "string" && typeof second)
            //For treating non ASCII strings correctly e.g. Hängt
            return Intl.Collator().compare(first, second)
          return compareNumbers(first, second)
        }
        else {
          if (typeof first === "string" && typeof second)
            return Intl.Collator().compare(second, first)

          return compareNumbers(second, first)
        }

      })
    }

    //the data shown is the data on the current page
    if(props.enablePagination)
      setTableData(full_data.items.slice(current_page * page_size, (current_page + 1) * page_size))
    else
      setTableData(full_data.items)
  }, [full_data, sorted_column, current_page])

  function getRowBackgroundColor(is_filter,index) {
    if(is_filter)
      return "#638FC0"
    if(index % 2 === 0)
      return "#E8E8E8"
    return "#FFFFFF"
  }

  return full_data && <>

    <Table sor className={props.filter_param ? "table-hover" : "" + props.className} size="" style={props.style}>
      {
      table_data.some(row => !!row.sub_data) && <colgroup>
        <col span={full_data.colum_names.length + (props.filter_param ? 1 : 0)}/>
        <col class="outlined-column"/>
        {/* <col span="3"/> */}
      </colgroup>
      }
      <thead>

        <tr style={
          { height: 0 }
        }>
          {
            full_data.colum_names && full_data.colum_names.map((header, index) =>
              <th key={index} onClick={() => {
                setSortedColumn({
                  column: index,
                  ascending: setSortOrderIsAscending(!sort_order_is_ascending)
                }); setCurrentPage(0)
              }}>
                {header}
              </th>
            )
          }
          {/* For the detailed expansion */}
          {table_data.some(row => !!row.sub_data) && <th>

          </th>}
        </tr>
      </thead>
      <tbody>
        {
          table_data.map((row, row_index) =>
          <>
              <tr onClick={(event) => {
                //for filtering

                //don't filter when clicking the expand button
                if (event.target.id === "expand-td")
                  return;
                if (!props.filter_param) return
                if ((row.values[0].filterableValue ?? row.values[0].sortableValue) !== props.filter_param.value) {
                  props.filter_param.setValue(row.values[0].filterableValue ?? row.values[0].sortableValue)
                }
                else {
                  props.filter_param.setValue(null)
                }

              }} key={row_index} style={{
                backgroundColor: getRowBackgroundColor(props.filter_param && ((row.values[0].filterableValue ?? row.values[0].sortableValue) === props.filter_param.value),row_index),
            }}
              className={props.filter_param ? "hand-on-hover" : ""}


            >
              {
                row.values.map((column, column_index) => {
                  return <td  key={column_index} className={"no-wrap" + (column_index === 0 ? " table-truncate-text " : "") + (props.filter_param && ((row.values[0].filterableValue ?? row.values[0].sortableValue) === props.filter_param.value) ? " text-white" : " text-js-gray")} >
                    {column.readableValue}
                  </td>
                }

                )
              }
                {row.sub_data && <td id="expand-td" onClick={() => {
                   shown_indices[row_index] = !shown_indices[row_index]
                   // for some reason just passing in the same array doesn't trigger a rerender so we force one by creating a new array
                   setShownIndices([...shown_indices])
                }}>{shown_indices[row_index]? "-": "+"}</td>}

            </tr>
              {row.sub_data &&
              (row.sub_data.items.length > 0 && <tr className="p-0">
              <td colSpan={2} className="p-0">
              {/* For sub tables */
                <JsModal show={shown_indices[row_index] === true} header={row.values[0].readableValue} onClose={() => { shown_indices[row_index] = false; setShownIndices([...shown_indices]) }}>
                  <JSTable enablePagination={false} data={row.sub_data} className={"sub-table"} style={{marginTop: "0", marginBottom: "0", margin: "0 10px 0 10px" }} />
                </JsModal>
              }

              </td>
              {/* When no items render placeholder */}
              </tr> || <tr className="p-0">
                <td colSpan={"100%"} className="p-0">
                  {
                    shown_indices[row_index] === true && <span>No data</span>
                  }
                </td>

                </tr>
              )
            }

            </>
          )
        }
      </tbody>
    </Table>
    {props.enablePagination && full_data && full_data.items && full_data.items.length > page_size &&
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={"page-item" + ((current_page === 0) ? " disabled" : "")}><a className="page-link" href="#/" onClick={() => { setPageAndResetSelected(current_page - 1) }}>Previous</a></li>
          {
            page_buttons
          }
          <li className={"page-item" + ((current_page === full_data.length / page_size - 1) ? " disabled" : "")}><a className="page-link" href="#/" onClick={() => { setPageAndResetSelected(current_page + 1) }}>Next</a></li>
        </ul>
      </nav>
    }
  </>
}