import moment from 'moment'
function JSAPI() {
  const params = new URLSearchParams(window.location.search);

  const token = params.get('token')

  const endpoint = params.get('api')

  const myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + token);

  function fetchSecure(url) {
    return fetch(url, {
      method: 'GET',
      headers: myHeaders,
    })
  }
  const api_version = "v1"
  function handleURLParams(path, params) {
    let request_url = endpoint + "/" + api_version + path + "?"
    for (const key in params) {
      //falsey values (e.g. null) are ignored
      if (params[key]) {
        // if the type is a Moment datetime object we have to format it to a string first
        if (params[key] instanceof moment) {
          request_url += "&" + key + "=" + params[key].format()
        }
        else {
          request_url += "&" + key + "=" + params[key]
        }
      }
    }
    return request_url
  }



  function getCountries(params) {
    params.returnCities = true
    const url = handleURLParams("/countries", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getRegions(params) {
    const url = handleURLParams("/regions", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getCities(params) {
    const url = handleURLParams("/cities", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getLivestreamAssets(params) {
    const url = handleURLParams("/assets", params)

    return fetchSecure(url).then(result => result.json())
  }

  function getViewersOverTime(params) {
    const url = handleURLParams("/assets/viewers-over-time", params)

    return fetchSecure(url).then(result => result.json())
  }

  function getOperatingSystems(params) {
    const url = handleURLParams("/operating-systems", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getBrowsers(params) {
    const url = handleURLParams("/browsers", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getDevices(params) {
    const url = handleURLParams("/devices", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getTotalViewers(params) {
    const url = handleURLParams("/viewers-total", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getTotalTraffic(params) {
    const url = handleURLParams("/traffic", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getTrafficOverTime(params) {
    const url = handleURLParams("/traffic-over-time", params)
    return fetchSecure(url).then(result => result.json())
  }

  function getReferrers(params) {
    const url = handleURLParams("/referrers", params)
    return fetchSecure(url).then(result => result.json())

  }


  return {
    getCountries,
    getRegions,
    getCities,
    getLivestreamAssets,
    getViewersOverTime,
    getOperatingSystems,
    getBrowsers,
    getDevices,
    getTotalViewers,
    getTotalTraffic,
    getTrafficOverTime,
    getReferrers
  }

}

export default JSAPI
